<template>
    <h2 class="evaluation-h2">評価・使い方</h2>
    <div class="detail-content" v-html="content"></div>
  </template>
  
  <script>
  export default {
    props: {
      content: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .evaluation-h2 {
    margin-top: 20px;
  }
  .detail-content {
    width: 80%;
    padding: 20px;
    word-break: break-all;
  }
  
  .detail-content h2 {
    margin-top: 20px;
  }
  
  .detail-content p {
    margin-bottom: 20px;
    text-align: left;
  }
  </style>
  