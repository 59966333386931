<template>
    <RecentPost></RecentPost>
</template>

<script>
import RecentPost from './Home/RecentPost.vue';
export default {
    components: {
        RecentPost,
    },
    name: 'HomeView',
}
</script>

<style scoped>

</style>
