<template>
 <div class="header">
  <h1>Marvel Snap Sefway</h1>
      <nav>
        <ul>
          <li><router-link to="/">Home</router-link></li>
        </ul>
      </nav>
 </div>
 <div class="content">
  <div class="main-content">
    <router-view></router-view>
  </div>
  <div class="sidebar">
    <h2>Sidebar</h2>
    <p>Some content for the sidebar</p>
  </div>
 </div>
  <div class="footer">
    <p>&copy; 2024 My Awesome Site. All rights reserved.</p>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
@import './assets/styles/_variables.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-color;
  background-color: $background-color;

  .header {
    background-color: $primary-color;
    padding: 10px;
    color: white;
    text-align: center;

    @media (max-width: $breakpoint-sm) {
      padding: 10px;
    }
  }

  .content {
    display: flex;

    .main-content {
      flex: 8;
    }
    
    .sidebar {
      flex: 2;
      padding: 10px;

      @media (max-width: $breakpoint-sm) {
        display: none;
      }
    }
    @media (max-width: $breakpoint-sm) {
      padding: 10px;
    }
  }

  .footer {
    background-color: $secondary-color;
    padding: 20px;
    color: white;
    text-align: center;

    @media (max-width: $breakpoint-sm) {
      padding: 10px;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  #app {

  }
}

@media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
  #app {
    .content {

    }
  }
}

@media (min-width: $breakpoint-lg) {
  #app {
    .content {
      padding: 10px;
    }
  }
}

</style>
