<template>
    <div class="card-detail-content">
        <CardInfo 
            :imgPath="imgPath" 
            :title="title" 
            :series="series" 
            :cost="cost" 
            :power="power" 
            :text="text"
            :seriesLink="`/series/${series}`" 
            :costLink="`/cost/${cost}`" 
            :powerLink="`/power/${power}`" 
        />
    <RelatedCard />
    <PostDetail :content="content" />
    </div>
</template>

<script>
import axios from 'axios';
import CardInfo from './CardDetail/CardInfo.vue';
import RelatedCard from './CardDetail/RelatedCard.vue';
import PostDetail from './CardDetail/PostDetail.vue';

export default {
    props: {
        secondPath: String,
        id: Number
    },
    components: {
        CardInfo,
        RelatedCard,
        PostDetail
    },
    name: 'CardDetailView',
    data() {
        return {
            imgPath: '',
            title: '',
            series: 0,
            cost: 0,
            power: 0,
            text: '',
            content: ''
        };
    },
    mounted() {
    this.fetchCardDetails();
    },
    methods: {
        fetchCardDetails() {
            const cardApiUrl = `https://marvelsnap.db.sefway.com/api/cards/get/name/${this.secondPath}`;
            const articleApiUrl = `https://marvelsnap.wordpress.sefway.com/wp-json/wp/v2/posts/${this.id}`;
            
            // 両方のAPIリクエストを並行して行う
            axios.all([
                    axios.get(cardApiUrl),
                    axios.get(articleApiUrl)
                ])
                .then(axios.spread((cardResponse, articleResponse) => {
                    // カードAPIのレスポンス処理
                    const cardData = cardResponse.data;
                    this.imgPath = cardData.img_path || '';
                    this.title = cardData.name_jp || 'デフォルトのタイトル';
                    this.series = cardData.series || ''; 
                    this.cost = cardData.cost || 0;
                    this.power = cardData.power || 0;
                    this.text = cardData.text || 'デフォルトのテキスト';
                    
                    // 記事APIのレスポンス処理
                    const articleData = articleResponse.data;
                    this.content = articleData.content.rendered || ''; // 記事コンテンツを設定
                }))
                .catch(error => {
                    console.error('データの取得に失敗しました:', error);
                });
            }
    },
}
</script>

<style scoped>
.card-detail-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    max-width: 100%;
}
</style>
