import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../components/Home.vue'
import CardDetail from '../components/CardDetail.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/card/:secondPath',
    name: 'CardDetail',
    component: CardDetail,
    props: (route) => ({ secondPath: route.params.secondPath, id: route.query.id })
  }  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
