<template>
    <h2>関連カード</h2>
    <div class="related-cards">
        <img src="https://marvelsnap.db.sefway.com/images/DEADPOOL.jpg" alt="">
        <img src="https://marvelsnap.db.sefway.com/images/DEADPOOL.jpg" alt="">
        <img src="https://marvelsnap.db.sefway.com/images/DEADPOOL.jpg" alt="">
        <img src="https://marvelsnap.db.sefway.com/images/DEADPOOL.jpg" alt="">
        <img src="https://marvelsnap.db.sefway.com/images/DEADPOOL.jpg" alt="">
        <img src="https://marvelsnap.db.sefway.com/images/DEADPOOL.jpg" alt="">
        <img src="https://marvelsnap.db.sefway.com/images/DEADPOOL.jpg" alt="">
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
h2 {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 40px;
}
.related-cards {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 40px;
    
    img {
    width: 100px;
    height: 150px;
    margin-left: 20px;
}
}

</style>
