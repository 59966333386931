<template>
    <div>
      <h2>最新の記事</h2>
      <div>
        <div 
            v-for="post in posts" 
            :key="post.id" 
            @click="goToPost(post.id, post.link)" 
            style="cursor: pointer; margin-bottom: 10px;">
            <h3>{{ post.title.rendered }}</h3>
            <p>{{ formatDate(post.date) }}</p>
        </div>
    </div>

      <button @click="fetchPosts">記事を再取得</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        posts: [],
        perPage: 6, 
      };
    },
    methods: {
      fetchPosts() {
        const apiUrl = `https://marvelsnap.wordpress.sefway.com/wp-json/wp/v2/posts?per_page=${this.perPage}&_fields=date,title,id,link`;
        axios.get(apiUrl)
          .then(response => {
            this.posts = response.data;
          })
          .catch(error => {
            console.error('Error fetching posts:', error);
          });
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      },
      goToPost(postId, postLink) {
        const path = new URL(postLink).pathname;
        const pathSegments = path.split('/').filter(segment => segment !== '');
        
        // 一つ目はカテゴリー
        const firstPath = pathSegments[0] || '';
        const secondPath = pathSegments[1] || '';

        // 取得したパスをログに出力（必要なら）
        console.log('First Path:', firstPath);
        console.log('Second Path:', secondPath);

        if (firstPath == 'card') {
          this.$router.push({ name: 'CardDetail', params: { secondPath: secondPath }, query: { id: postId } });
        } else {
          this.$router.push({ name: 'Home'});
        } 
    }

    },
    mounted() {
      this.fetchPosts(); // コンポーネントがマウントされたときにデータを取得
    }
  };
  </script>
  
  <style scoped>
  /* スタイルをここに追加できます */
  </style>
  