<template>
    <div class="card-info">
      <div class="card-image">
        <img :src="`https://marvelsnap.db.sefway.com/${imgPath}`" alt="Card Image" />
      </div>
      <div class="card-details">
        <h1>{{ title }} 評価・採用デッキ</h1>
        <table>
          <tr>
            <td>シリーズ</td>
            <td>{{ series }} <a :href="seriesLink">(シリーズ{{ series }}のカード一覧)</a></td>
          </tr>
          <tr>
            <td>コスト</td>
            <td>{{ cost }} <a :href="costLink">(コスト{{ cost }}のカード一覧)</a></td>
          </tr>
          <tr>
            <td>パワー</td>
            <td>{{ power }} <a :href="powerLink">(パワー{{ power }}のカード一覧)</a></td>
          </tr>
          <tr class="card-info-text">
            <td>テキスト</td>
            <td>{{ text }}</td>
          </tr>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imgPath: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      series: {
        type: Number,
        required: true
      },
      cost: {
        type: Number,
        required: true
      },
      power: {
        type: Number,
        required: true
      },
      text: {
        type: String,
        required: true
      },
      seriesLink: {
        type: String,
        required: false
      },
      costLink: {
        type: String,
        required: false
      },
      powerLink: {
        type: String,
        required: false
      }
    }
  }
  </script>
  
  <style scoped>
  .card-info {
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .card-image {
    flex: 2;
    img {
    width: 200px;
    height: auto;
    margin-right: 20px;
  }
  } 
  
  .card-details {
    flex: 8;
    display: flex;
    flex-direction: column;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
  }

  .card-info-text {
    height: auto;
    word-break: keep-all;
  }
  
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  h2 {
    margin-bottom: 10px;
  }
  
  /* レスポンシブデザイン */
  @media (max-width: 768px) {
    .card-info {
      flex-direction: column;
      align-items: center;
    }
  
    .card-image img {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .card-details {
      width: 100%;
    }
  
    table {
      width: 100%;
    }
  }
  </style>
  